.mapboxgl-ctrl-group button {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25) !important;
    border: none !important;
    background: #fff !important;
}

.mapboxgl-ctrl-group button:last-child {
    border-radius: 15px !important;
}

.mapboxgl-ctrl-group {
    background: transparent !important;
    border-radius: 15px !important;
    box-shadow: none !important;
}

.mapboxgl-ctrl-zoom-in {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 15px 15px 0 0 !important;
}

.mapboxgl-ctrl-zoom-out {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.25) !important;
    border-radius: 0 0 15px 15px !important;
    margin-bottom: 2px;
}

.mapboxgl-ctrl-geolocate {
    margin-right: 4.5px;
}

.mapboxgl-ctrl-compass:focus {
    border-radius: 15px !important;
}

.mapboxgl-popup {
    max-width: 200px;
}

.mapboxgl-popup-content {
    background-color: transparent;
    background-image: linear-gradient(rgba(255,255,255,1), rgba(255,255,255,0.7));
    border-radius: 15px;
    text-align: center;
    line-height: 23px;
    font-family: 'Lato', sans-serif;
    z-index: 5;
}

.mapboxgl-popup-tip {
    z-index: 6;
    border-color: transparent !important;
}

.mapboxgl-popup-close-button {
    background-color: transparent !important;
    border-radius: 0 15px 0 5px !important;
    height: 25px !important;
    width: 25px !important;
}

.mapboxgl-popup-close-button:hover {
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.popup-info-box {
    border-radius: 8px;
    /* background: rgba(255,255,255,0.5) !important; */
    padding: 5px;
    /* box-shadow: 0px 0px 0px 1px rgb(50, 50, 50); */
    /* box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.5); */
    font-family: 'Lato', 'Open Sans', sans-serif;
}

.popup-info-text {
    margin-top: 0.15em;
    font-size: 0.9em;
    font-weight: normal;
    color: rgb(50, 50, 50);
    display: inline-block;
    line-height: 1.4;
    font-family: 'Rosario', 'Open Sans', 'Source Sans Pro', sans-serif;
}

.popup-info-text br {
    margin-bottom: 0.3em;
}

.view-sightings {
    font-size: 1em;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px;
    height: 25px;
    width: 100%;
    border-radius: 5px;
    /* background: #fff; */
    filter: none;
    box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer;
    background: #fff;
    /* background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,1)); */
}

.mapboxgl-popup-content>div {
    border-radius: 15px;
    overflow: hidden;
    padding: 3px;
}