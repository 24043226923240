html {
  --show-hide-rotate: 180deg;
  --show-hide-offset-x: -50%;
  --show-hide-offset-y: 0%;
}

.App {
  position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.showHide {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 50%;
  margin: auto;
  background: transparent;
  background-image: url('../img/rounded-triangle.svg');
  background-position-y: -1px;
  position: absolute;
  width: 30px;
  height: 30px;
  border: none;
  z-index: 2;
  border: 5px solid transparent;
  background-size: cover;
  background-repeat: no-repeat;
  cursor: pointer;
}

.showHide:active {
  box-shadow: none !important;
}

.explorer-panel-container {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  max-height: 50%;
  overflow: hidden;
  flex-basis: 0;
  background: #fff;
  transition: all 0.1s;
  text-align: center;
}

@media (orientation:landscape) {
  .App {
    flex-flow: row;
  }

  .showHide {
    left: 0;
    top: calc(50% - 20px);
  }

  html {
    --show-hide-rotate: 90deg;
    --show-hide-offset-x: 0%;
    --show-hide-offset-y: -50%;
  }

  .explorer-panel-container{
    max-height: 100%;
  }
}

.explorer-panel-container::before {
  content: "";
  position: absolute;
  inset:0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 5;
  pointer-events: none;
  box-shadow: inset 0 0px 6px 0px rgba(0, 0, 0, 0.5);
}

.map-area-container {
  position: relative;
  flex: 1 1 auto;
  display: block;
}

.map-container {
  width: 100%;
  height: 100%;
  margin: 0;
}

.generic-button {
  position: relative;
  background: #fff;
  margin: 5px;
  border-radius: 5px;
  padding: 3px 5px;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.25) !important;
  cursor: pointer;
  height: 26px;
  max-height: 26px;
  display: inline-block;
  color: #333;
}