.place-search-input {
    width: calc(100% - 10px);
    height: 40px;
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.place-search-input>input[type=text] {
    width: 60%;
    height: 20px;
    padding: 5px;
    margin: 10px;
    background: transparent;
    box-shadow: inset 0 1px 2px 0 rgba(0, 0, 0, 0.3);
}

.go-button {
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.3);
    cursor: pointer;
    text-align: center;
}

.x-button::after, .x-button::before {
    background-color: hsla(0,0%,10%,.5);
    border-radius: 3px;
    content: "";
    inset: 0;
    margin: auto;
    position: absolute;
}
.remove-isochrone {
    transform: rotate(45deg);
}
.x-button::before {
    height: 15px;
    width: 4px;
}

.x-button::after {
    height: 4px;
    width: 15px;
}

.remove-isochrone {
    font-size: 10px;
}

.remove-isochrone {
    background: rgb(198, 68, 68);
    color: #fff;
}

.location-boxes-container {
    position: relative;
    width: 100%;
    max-height: calc(100% - 60px);
    overflow-y: scroll;
    padding-top: 10px;
}

.location-box {
    /* background: #abc; */
    width: calc(100% - 20px);
    padding: 5px;
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    cursor: pointer;
}

.location-box:hover {
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2);
}

.location-name {
    font-weight: 600;

}

.mitm-search {
    padding-top: 10px;
    display: flex;
    max-height: calc(100% - 10px);
    flex-direction: column;
    align-items: center;
    justify-content: center;
}