.sightings-explorer-container>details>summary {
    font-weight: 200;
    padding: 0;
    transition: all 0.1s;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    box-shadow: inset 0 -2px 2px -2px rgba(0, 0, 0, 0.2);
    color: hsl(158, 30%, 35%);
    background: hsla(158, 40%, 80%, 0.2);
    overflow: hidden;
}

.sightings-explorer-container>details>summary:hover {
    background: hsla(158, 40%, 80%, 0.3);
}


summary::-webkit-details-marker {
    display: none;
}

summary {
    cursor: pointer;
    list-style: none
}

summary::-webkit-details-marker {
    display: none;
}

.scrubbable-number {
    font-size: 25px;
    font-weight: 600;
    height: 30px;
    filter: drop-shadow(1px 1px 0px #333);
    user-select: none;
}

.sightings-explorer-container {
    position: absolute;
    margin: 0;
    width: 100%;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-flow: column;
    align-items: center;
}

.dropdown {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
}

.explorer-input>label {
    user-select: none;
}

.sightings-explorer-container>details {
    width: 100%;
    height: fit-content;
    transition: all 0.2s;
}

.details-container {
    width: calc(100% - 16px);
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 8px;
    gap: 8px;
}

.sightings-explorer-container>details[open]>summary {
    background: hsla(15, 73%, 80%, 0.2);
    font-size: 1em;
    height: 20px;
}

.sightings-table-container {
    position: relative;
    width: calc(100% - 10px);
    padding: 5px;
    flex: 0 1 auto;
    overflow: auto;
}

.sightings-table {
    border-collapse: collapse;
    width: 100%;
}

.sightings-table th {
    text-align: center;
}

.sightings-table td, .sightings-table th {
    padding: 1px;
}

.sightings-table th {
    padding-top: 0;
    padding-bottom: 2px;
    text-align: center;
    color: #444;
}

.sightings-table .species-count-row:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
}

.div-bar-horizontal {
    font-weight: 600;
    font-size: 0.7em;
    float: right;
    border-radius: 10px 2px 2px 10px;
    color: #fff;
    text-align: center;
    padding-right: 5px;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.table-species-name {
    font-family: "Alegreya Sans";
    font-weight: 600;
    color: hsl(158, 30%, 35%);
    background: transparent;
    border-radius: 5px;
    padding: 2px 5px;
    /* box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.3); */
    text-align: left;
    overflow-x: visible;
    white-space: nowrap;
    max-width: 100px;
}

.additional-row-info>td {
    transition: all 0.2s;
    column-span: 2;
}

.additional-row-info-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

.species-count-row td:first-child {
    width: 75px;
    text-align: left;
}

.sightings-go-button {
    margin: 10px;
    height: 30px;
    width: 120px;
    font-size: 0.8em;
    font-weight: 600;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 10px;
    box-shadow: 0 2px 0px 0.5px rgba(0, 0, 0, 0.3);
    cursor: pointer;
    border: 5px solid transparent;
    transition: all 80ms;
}

.sightings-go-container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.sightings-go-button:active {
    box-shadow: 0 0px 0px 0.5px rgba(0, 0, 0, 0.3) !important;
    transform: translate(0, 2px);
}

.nbn-go-button {
    background-image: url('../img/nbn_atlas_text_logo.webp');
}

.inat-go-button {
    background-image: url('../img/inaturalist_text_logo.svg');
}

.explorer-input {
    font-size: 0.8em;
    color: #aaa;
    border-radius: 10px;
    background: #fafafa;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: fit-content;
    height: fit-content;
    border-radius: 5px;
    padding: 5px;
    box-shadow: 0 1px 1px -1px rgba(0, 0, 0, 0.3);
    transition: all 0.2s;
}

.remove-inat-markers {
    border: none;
    border-radius: 0px;
    cursor: pointer;
    border-color: #fff;
    border-style: solid;
    border-width: 3px;
    height: 19px;
    text-align: center;
    font-family: 'Rosario', sans-serif;
    font-weight: bold;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.35);
    position: relative;
    font-size: 16px;
    display: flex;
    background: rgba(255, 120, 150);
    justify-content: center;
    align-items: center;
    color: #fff;
    width: fit-content !important;
    overflow: visible;
    white-space: nowrap;
    padding: 10px;
    margin: 5px;
}

.sightings-header-buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 5px;
}

.sightings-header-buttons a {
    margin: auto;
    height: 26px;
    display: inline-block;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    color: #333;
    font-weight: 600;
}

.sightings-header-buttons > * {
    margin: auto;
}

.sightings-header-buttons > a > p {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 5px;
}

.button-mini-image {
    height: 26px;
    width: 26px;
}