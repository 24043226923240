.butterfly-container {
	margin-left: max(calc(50% - 500px), 0px);
	width: min(90vw, 750px);
	display: flex;
	justify-content: flex-end;
	align-items: center;
}

.canvas-container>canvas {
	position: relative;
}

.canvas-container {
	position: absolute;
	bottom: 0;
	margin: auto;
	width: 100%;
	max-width: 100%;
	display: flex;
	overflow: hidden;
	border-radius: 0, 0, 8px 8px;
	pointer-events: none;
	filter: drop-shadow(0px -1px 0px #fff);
}

.month-on-canvas {
	position: absolute;
	display: inline-flex;
	width: calc(100% / 12);
	padding-top: 1px;
	height: 100%;
	line-height: 1em;
	justify-content: center;
	text-align: center;
	font-family: 'Source Sans Pro';
	font-size: 0.6em;
	color: rgba(0, 0, 0, 0);
}

.month-on-canvas:hover {
	color: rgba(0, 0, 0, 1);
	background: hsla(140, 40%, 40%, 0.05);
}

.butterfly-info {
	max-width: 250px;
	padding: 2px 5px;
	margin: 2px;
	font-weight: 600;
	color: #333;
	background: rgb(233, 241, 241);
	cursor: pointer;
	border-radius: 5px;
	word-wrap: normal;
}

@media (max-width: 600px) {
	.butterfly-info {
		width: 80px;
		font-size: 0.9em !important;
	}
}

@media (max-width: 750px) and (min-width: 600px) {
	.butterfly-info {
		width: calc(100vw - 500px);
	}
}

#header {
	width: 100%;
	font-size: 2em;
	font-weight: 700;
	text-align: center;
	color: #333;
}

#but-details-container {
	position: relative;
	padding-top: 5px;
	padding-bottom: 25px;
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	animation: expand .2s ease-in-out;
	overflow: hidden;
}

#but-details-container>* {
	margin: 5px;
}

.species-main-image {
	position: relative;
	width: 100%;
	height: 100%;
	border-radius: 5px;
	background-image: linear-gradient(to bottom, #bedada 0%, #e0ebeb 51%);
	background-size: cover;
	color: #fff;
	background-position: center;
}

.bin-fam-names {
	position: absolute;
	top: 20%;
	left: 0;
	width: fit-content;
	display: block;
}

.bin-fam-names>div {
	width: fit-content;
}

.bin-fam-names>div>b {
	font-weight: 600;
	font-size: calc(1em - 2px);
	padding: 0px 4px 0px 4px;
	color: #000;
	background: rgba(255, 255, 255, 0.4);
}

.bin-fam-names>div>i {
	font-family: 'Lora';
	font-size: 1em;
	font-weight: 400;
	padding: 0px 4px 2px 4px;
	background: rgba(0, 0, 0, 0.5);
}

.butterfly-further-info {
	width: 100%;
	height: 100%;
	border-radius: 5px;
	display: flex;
	flex-wrap: wrap;
	flex-direction: row;
	overflow: hidden;
}


@keyframes expand {
	from {
		height: 0;
	}
}

.image-credit {
	font-size: 0.9em;
	position: absolute;
	bottom: 30px;
	right: 0;
	width: fit-content;
	background: rgba(0, 0, 0, 0.3);
	padding: 0px 4px 0px 4px;
	text-align: right;
}

.image-credit .jsa {
	color: #cfc;
	font-style: italic;
	cursor: pointer;
}

.species-info-links {
	position: absolute;
	top: 0;
	width: calc(100% - 5px);
	height: 15%;
	display: flex;
	flex-direction: row;
	padding: 5px;
	justify-content: center;
	align-items: center;
	z-index: 5;
}

.bf-link {
	margin: 0 15px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 45px;
	height: 45px;
	background-position: center;
	background-size: cover;
	cursor: pointer;
	transition: 0.2s;
	filter: drop-shadow(2px 2px 0px rgba(255, 255, 255, 1));
}

.bf-link:hover {
	filter: drop-shadow(3px 3px 0px rgba(0, 0, 0, 0.4));
}

.bf-link-ecosia {
	background-image: url("../img/ecosia_mglass.png");
}

.bf-link-wiki {
	background-image: url("../img/wikipedia.png");
}

.bf-link-nbn {
	background-image: url("../img/nbn_atlas.png");
}