#outwhere-logo {
	position: relative;
	width: 100px;
	height: 100px;
}

.ow-circle {
	position: absolute;
	border-radius: 50%;
	transform: translate(-50%, -50%);
	transition: 20ms ease-in-out;
	animation: load1 1.25s infinite alternate;
}

#oc1 {
	top: 13%;
	left: 44.5%;
	width: 24%;
	height: 24%;
	background: rgb(126, 196, 149);
}

#oc2 {
	top: 24%;
	left: 42%;
	width: 10%;
	height: 10%;
	background: rgb(72, 133, 80);
}

#oc3 {
	top: 25%;
	left: 56.5%;
	width: 23%;
	height: 23%;
	background: rgb(67, 159, 71);
}

#oc4 {
	top: 36%;
	left: 44.5%;
	width: 28%;
	height: 28%;
	background: rgb(72, 164, 102);
}

#oc5 {
	top: 48.2%;
	left: 56.2%;
	width: 23.5%;
	height: 23.5%;
	background: rgb(60, 149, 40);
}

#oc6 {
	top: 48.5%;
	left: 32.5%;
	width: 19%;
	height: 19%;
	background: rgb(61, 189, 75);
}

#oc7 {
	top: 60.5%;
	left: 32.5%;
	width: 25%;
	height: 25%;
	background: rgb(49, 165, 72);
}

#oc8 {
	top: 60.5%;
	left: 20.5%;
	width: 24%;
	height: 24%;
	background: rgb(66, 169, 70);
}

#oc9 {
	top: 60%;
	left: 52.5%;
	width: 8.5%;
	height: 8.5%;
	background: rgb(94, 201, 109);
}

#oc10 {
	top: 60.5%;
	left: 68.5%;
	width: 24%;
	height: 24%;
	background: rgb(87, 188, 91);
}

#oc11 {
	top: 72.4%;
	left: 56.2%;
	width: 28%;
	height: 28%;
	background: rgb(65, 170, 85);
}

#oc12 {
	top: 72.2%;
	left: 68.3%;
	width: 15%;
	height: 15%;
	background: rgb(86, 194, 105);
}

#oc13 {
	top: 84.2%;
	left: 44.3%;
	width: 17%;
	height: 17%;
	background: rgb(52, 159, 118);
}

#oc14 {
	top: 84.2%;
	left: 56.3%;
	width: 24%;
	height: 24%;
	background: rgb(80, 188, 99);
}

#oc15 {
	top: 84.2%;
	left: 68.3%;
	width: 16.2%;
	height: 16.2%;
	background: rgb(49, 153, 79);
}

#oc16 {
	top: 84.3%;
	left: 80.4%;
	width: 28.3%;
	height: 28.3%;
	background: rgb(58, 188, 158);
}

@keyframes load1 {
	0% {
		height: 0px;
		width: 0px;
		left: 50%;
		top: 50%;
		transform: translate(0, 100%);
	}
}